import React,{useEffect} from "react";
import Carousel from 'react-bootstrap/Carousel';

import AOS from 'aos';
import 'aos/dist/aos.css';

import aboutbanner from '../images/aboutbanner.jpg';
import iso from '../images/iso.jpg';

export default function About() {
    useEffect(() => {
        AOS.init();
      }, [])
  return (
    <div>

{/* <!----------------========================================== start of banner ======================================----------------> */}
<div className='container-fluid banner'>
        <Carousel autoPlay={true} interval={4000} controls={false} indicators={false} pause={false}>
            <Carousel.Item>
              <img
                className="d-block w-100"
                src={aboutbanner}
                alt="First slide"
              />
              <Carousel.Caption>
              <div class="carousel-caption row">
                    <div class="col-md-4 col-sm-12 col-xs-12" data-aos="fade-up">
                        <div class="row">
                            <h2>About Us</h2>
                        </div>
                    </div>
                </div>
              </Carousel.Caption>
          </Carousel.Item>
      </Carousel>   
</div>
{/* <!----------------========================================== end of banner ======================================----------------> */}

{/* <!----------------========================================== start of about_page ======================================----------------> */}
<div class="container-fluid about_page">
	<div class="row">
        <div class="col-md-12 col-sm-12 col-xs-12 mt30">
            <h6>Company Profile</h6>
            <h2>Trusted Manufacturer & Exporter of Fiber</h2>
            <p>Fiberwell Pvt. Ltd. [FWPL] is a manufacturer and exporter of Polypropylene macro fibers for concrete reinforcement. Fiberwell Pvt. Ltd. is an ISO 9001:2015 certified company was established in the year 2021. The company is headed by a group of second-generation technocrats aiming to be the market leader in the industry.</p>
            <p>At FWPL, we have a modern infrastructure and machinery facility that enables us to provide synthetic fiber concrete reinforcement as one of the most reliable and highest performing fiber reinforcements. Backed and supported by a team of skilled professionals such as production managers, engineers, technicians, quality controllers and other supporting staff.  It is made out of 100% virgin polypropylene using international technology. This offers high strength, durability, crack-free, leakage-free, and damp-free concrete and plaster.</p>
            <ul>
                <li>We have a modern infrastructure and machinery facility</li>
                <li>Commitment to provide the highest quality products and services</li>
                <li>Our products endures with great strength and is reliable</li>
                <li>Our products are useful in making cost-effective infrastructure</li>
            </ul>
            <p>It is more cost effective than other waterproofing materials available in the market and can be easily mixed in a mortar for concrete and plaster. Our products are useful in making cost-effective infrastructure that endures with great strength and is reliable. We are well known for the diversity of the products that we manufacture and supply while maintaining the precise quality that is mentioned by our clients in their product requirements.</p>
        </div>
        <div class="col-md-12 col-sm-12 col-xs-12 brdr mt30">
            <h4>Company Basic Information</h4>
            <table class="table table-responsive">
                <tbody>
                    <tr>
                        <th class="col-1">Specification</th>
                        <th class="col-2">Details</th>
                    </tr>
                    <tr>
                        <td class="col-1">Nature of Business</td>
                        <td class="col-2">Manufacturer & Exporter</td>
                    </tr>
                    <tr>
                        <td class="col-1">Company CEO</td>
                        <td class="col-2">Mitul Vaghasiya</td>
                    </tr>
                    <tr>
                        <td class="col-1">Registered Address</td>
                        <td class="col-2">Plot No. 254, G.I.D.C.-Chitra, Bhavnagar, Gujarat – 364 004</td>
                    </tr>
                    <tr>
                        <td class="col-1">Industry Type</td>
                        <td class="col-2">Manufacturing Unit</td>
                    </tr>
                    <tr>
                        <td class="col-1">Total Number of Employees</td>
                        <td class="col-2">20 to 60</td>
                    </tr>
                    <tr>
                        <td class="col-1">Year of Establishment</td>
                        <td class="col-2">2021</td>
                    </tr>
                    <tr>
                        <td class="col-1">Annual Turn Over</td>
                        <td class="col-2">Rs. 2 – 5 Crore</td>
                    </tr>
                    <tr>
                        <td class="col-1">Building Infrastructure</td>
                        <td class="col-2">Permanent</td>
                    </tr>
                    <tr>
                        <td class="col-1">Sampling Policy</td>
                        <td class="col-2">Free</td>
                    </tr>
                    <tr>
                        <td class="col-1">Customized Packaging</td>
                        <td class="col-2">Yes</td>
                    </tr>
                    <tr>
                        <td class="col-1">Mode of Payment</td>
                        <td class="col-2">Cash/Cheque</td>
                    </tr>
                </tbody>
            </table>
        </div>        
    </div>
    <div className="row mt15">
        <div className="col-md-4 col-sm-12 col-xs-12 mt30">
            <img className="d-block w-100" src={iso} alt="ISO Certificate"/>
        </div>
        <div className="col-md-8 col-sm-12 col-xs-12 mt30">
            <ul>
                <li><strong>ISO Certification :</strong>  ISO 9001:2015</li>
                <li><strong>Issuance Date :</strong>  16/02/2024</li>
                <li><strong>Expiry Date :</strong>  15/02/2027</li>
            </ul>
        </div>
    </div>
</div>
{/* <!----------------========================================== end of about_page ======================================----------------> */}

    </div>
  )
}
