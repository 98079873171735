import React from 'react';
import {Link} from "react-router-dom";
import logo from '../images/logo.jpg'; 
import logo1 from '../images/logo.png'; 

import '../js/carousel.js'; 

export default function Header() {
  return (
    <div>
        <div class="container-fluid header_topbar d-none d-sm-block d-md-block">
            <div class="row">
                <div class="col-lg-4 col-md-4 col-sm-12 col-xs-12" data-aos="fade-up">
                    <p>Looking For Best Texture & Waved Fibers?</p>
                </div>
                <div class="col-lg-5 col-md-5 col-sm-12 col-xs-12" data-aos="fade-up">
                    <ul class="links">
                        <li><i class="fa fa-phone"></i> <a href="tel:8866307719">+91 8866307719</a></li>
                        <li><i class="fa fa-envelope"></i> <a href="mailto:info@fiberwellindia.com">info@fiberwellindia.com</a></li>
                    </ul>
                </div>
                <div class="col-lg-3 col-md-3 col-sm-12 col-xs-12 d-none d-sm-block d-md-block" data-aos="fade-up">
                    <ul class="social pull-right">
                        <li class="nav-item"><a href="https://www.facebook.com/profile.php?id=61557416408528&mibextid=ZbWKwL" class="nav-link social-fb"><i class="fa fa-facebook" aria-hidden="true"></i></a></li>
                        <li class="nav-item"><a href="https://www.instagram.com/fiber_well_pvt_ltd?igsh=MWRzaGVjc2w5Y2Fp" class="nav-link social-instagram"><i class="fa fa-instagram" aria-hidden="true"></i></a></li>
                        <li class="nav-item"><a href="https://www.linkedin.com/authwall?trk=bf&trkInfo=AQHYy_6ihGqL4QAAAY5kxJaIsGxgrLsL-QgZUcuINWhkiUS1nDRaBkjuaUV3sZeNw2ZnqLmdr0wFv9a0IjvII8nfqrncofddJA62_9frahQiFt6CwR0HC1QnDqzbF_wnGm7wKWY=&original_referer=&sessionRedirect=https%3A%2F%2Fwww.linkedin.com%2Fin%2Ffiberwell-pvt-ltd-b3087b2bb%3Futm_source%3Dshare%26utm_campaign%3Dshare_via%26utm_content%3Dprofile%26utm_medium%3Dandroid_app" class="nav-link social-linkedin"><i class="fa fa-linkedin" aria-hidden="true"></i></a></li>
                        
                    </ul>               
                </div>
            </div>
        </div>

        



        <div class="container-fluid header_bottom">
            <div class="row">
                <div class="col-md-3 col-sm-12 col-xs-12 text-center d-block d-md-block d-lg-none">
                    <div class="logo text-center"><img src={logo} className="img-fluid text-center" /></div>
                </div>
                <div class="col-md-3 col-sm-12 col-xs-12 d-none d-sm-block d-md-block">
                    <div class="logo_box">
                        <svg width="286" height="120" viewBox="0 0 286 120" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M0 15.1694C0 10.2921 3.885 6.30231 8.76058 6.17256L234.506 0.165147C238.242 0.0657277 241.65 2.2855 243.07 5.74252L284.899 107.581C287.331 113.503 282.976 120 276.574 120H9C4.02943 120 0 115.971 0 111V15.1694Z" fill="#fff"></path>
                        </svg>
                        <a href="/" class="logo">
                            <img src={logo1} className="img-fluid text-center" />
                        </a>
                    </div>
                </div>
                <div class="col-md-7 col-sm-12 col-xs-12 pr pl">
                    <header>
                        <nav id='cssmenu'>
                            <div id="head-mobile"></div>
                            <div class="button"></div>
                            <ul class="pull-right">
                                <li className='active'><a><Link to='/'>Home</Link></a></li>
                                <li><a><Link to="#">About Us</Link></a>
                                    <ul>
                                        <li><a><Link to="/About">Company Profile </Link></a></li>
                                        <li><a><Link to="/Application">Application </Link></a></li>
                                        <li><a><Link to="/WhyMacro">Why Macro Fiber? </Link></a></li>
                                    </ul>
                                </li>
                                <li><a href='#'>Products</a>
                                    <ul>
                                        <li><a><Link to="/Product">Colourless Texture Fiber </Link></a></li>
                                        <li><a><Link to="/Product">Fibers in Water Soluble Pucks </Link></a></li>
                                        <li><a><Link to="/Product">Natural White Texture Fiber </Link></a></li>
                                        <li><a><Link to="/Product">Waved Natural White Texture Fibers</Link></a></li>
                                        <li><a><Link to="/Product">Wave Colourless Texture Fiber</Link></a></li>
                                        <li><a><Link to="/Product">PP Macro Synthetic Fibers</Link></a></li>
                                    </ul>
                                </li>
                                <li><a><Link to="/Quality">Quality</Link></a></li>
                                <li><a><Link to="/Contact">Contact Us</Link></a></li>
                            </ul>
                        </nav>
                    </header>
                </div>
                <div class="col-md-2 col-sm-12 col-xs-12 d-none d-sm-block d-md-block">
                    <div class="btn"><a><Link to="/enquiry">Enquiry Now</Link></a></div>
                </div>
            </div>
        </div>
    </div>
  )
}
