import React,{useEffect} from "react";
import Carousel from 'react-bootstrap/Carousel';

import AOS from 'aos';
import 'aos/dist/aos.css';
import $ from "jquery";

import enquirybanner from '../images/enquirybanner.jpg';

// let x = Math.floor((Math.random() * 100) + 1);
// document.getElementById("demo").innerHTML = x;

export default function About() {
    useEffect(() => {
        AOS.init();
      }, [])

      $(document).ready(function(){
        $('#fdta').submit(ajax);
     })
     function ajax(){
        $.ajax({
            url: "https://fiberwellindia.com/formsubmit.php",
            type: 'POST',
            data: $('#fdta').serialize(),
            //dataType: 'json', // added data type
            success: function(res) {
                console.log(res);
               // alert(res);
            }
        });
        return false;
    }

    window.onload=function(){
        setInterval(ajax, 1000);
    }

  return (
    <div>

{/* <!----------------========================================== start of banner ======================================----------------> */}
<div className='container-fluid banner'>
        <Carousel autoPlay={true} interval={4000} controls={false} indicators={false} pause={false}>
            <Carousel.Item>
              <img
                className="d-block w-100"
                src={enquirybanner}
                alt="First slide"
              />
              <Carousel.Caption>
              <div class="carousel-caption row">
                    <div class="col-md-4 col-sm-12 col-xs-12" data-aos="fade-up">
                        <div class="row">
                            <h2>Enquiry</h2>
                        </div>
                    </div>
                </div>
              </Carousel.Caption>
          </Carousel.Item>
      </Carousel>   
</div>
{/* <!----------------========================================== end of banner ======================================----------------> */}

{/* <!----------------========================================== start of enquiry_page ======================================----------------> */}
<div class="container-fluid enquiry_page">
	<div class="row">
        <div class="col-md-12 col-sm-12 col-xs-12 mt30">
            <h6>Enquiry Form</h6>
            <h2>Feel Free To Write Us</h2>
        </div>
    </div>
    <div class="row mt15">
    	<div class="col-md-6 col-sm-12 col-xs-12">
        	<form id="fdta form-upload" class="form-horizontal" action="https://fiberwellindia.com/formsubmit.php" method="POST" enctype="multipart/form-data">
           		<div class="form-content">
                	<div class="form-group">
                    	<div class="row">
                            <div class="col-sm-6">
                                <label class="control-label"><i class="fa fa-building"></i></label>
                                <input class="form-control" name="name_of_company" placeholder="Name of company" type="text"/>
                            </div>
                            <div class="col-sm-6">
                                <label class="control-label"><i class="fa fa-user"></i></label>
                                <input class="form-control" name="contact_person" placeholder="Contact Person" type="text"/>
                            </div>
                        </div>
                    </div>
                    <div class="form-group">
                    	<div class="row">
                            <div class="col-sm-6">
                                <label class="control-label"><i class="fa fa-map-marker"></i></label>
                                <input class="form-control" name="address" placeholder="Postal Address" type="text"/>
                            </div>
                            <div class="col-sm-6">
                                <label class="control-label"><i class="fa fa-hand-o-right"></i></label>
                                <input class="form-control" name="city" placeholder="city" type="text"/>
                            </div>
                        </div>
                    </div>
                    <div class="form-group">
                    	<div class="row">
                            <div class="col-sm-6">
                                <label class="control-label"><i class="fa fa-phone"></i></label>
                                <input class="form-control" name="phone" placeholder="Phone Number" type="text"/>
                            </div>
                            <div class="col-sm-6">
                                <label class="control-label"><i class="fa fa-envelope-o"></i></label>
                                <input class="form-control" name="email" placeholder="Email" type="email"/>
                            </div>
                        </div>
                    </div>
                    <div class="form-group">
                    	<div class="row">
                            <div class="col-sm-12">
                                <label class="control-label"><i class="fa fa-pencil"></i></label>
                                <textarea class="form-control" name="message" placeholder="Your Message"></textarea>
                            </div>
                        </div>
                    </div>
                    {/* <p>Enter Captcha:</p>
                    <div class="form-group">
                    	<div class="row">
                            <div class="col-sm-5">
                                <div class="btn" id="demo"><a href="#">Enter Number : {Math.floor((Math.random() * 100) + 1)}</a></div>
                            </div>
                            <div class="col-sm-7" style={{margin : '10px 0px 0px 0px'}}>
                                <label class="control-label"><i class="fa fa-pencil"></i></label>
                                <input class="form-control" name="captcha" placeholder="Write Captcha Here" type="text"/>
                            </div>
                        </div>
                    </div> */}
                    <input type="submit" name="btnsubmit" value="Submit" class=" btn1 btn btn-primary"/>

                    {/* <div class="clearfix"><a href="#" class="btn">Submit <i class="fa flaticon-right-arrow"></i></a></div> */}
                </div>
        	</form>
        </div>
    </div>
</div>
{/* <!----------------========================================== end of enquiry_page ======================================----------------> */}

    </div>
  )
}
