import React,{useEffect} from "react";
import Carousel from 'react-bootstrap/Carousel';

import AOS from 'aos';
import 'aos/dist/aos.css';

import appbanner from '../images/appbanner.jpg';
import app1 from '../images/app1.jpg';
import app2 from '../images/app2.jpg';
import app3 from '../images/app3.jpg';
import app4 from '../images/app4.jpg';

export default function Application() {
    useEffect(() => {
        AOS.init();
      }, [])
  return (
    <div>

{/* <!----------------========================================== start of banner ======================================----------------> */}
<div className='container-fluid banner'>
        <Carousel autoPlay={true} interval={4000} controls={false} indicators={false} pause={false}>
            <Carousel.Item>
              <img
                className="d-block w-100"
                src={appbanner}
                alt="First slide"
              />
              <Carousel.Caption>
              <div class="carousel-caption row">
                    <div class="col-md-4 col-sm-12 col-xs-12" data-aos="fade-up">
                        <div class="row">
                            <h2>Application</h2>
                        </div>
                    </div>
                </div>
              </Carousel.Caption>
          </Carousel.Item>
      </Carousel>   
</div>
{/* <!----------------========================================== end of banner ======================================----------------> */}

{/* <!----------------========================================== start of about_page ======================================----------------> */}
<div class="container-fluid about_page">
	<div class="row">
        <div class="col-md-12 col-sm-12 col-xs-12 mt30">
            <h6>About Us</h6>
            <h2>Application of Macro Fiber</h2>
        </div>
    </div>
    <div className="row">
        <div className="col-md-5 col-sm-12 col-xs-12 mt30">
            <img className="d-block w-100 img-fluid" src={app1}/>
        </div>
        <div className="col-md-6 col-sm-12 col-xs-12 mt30">
            <h4>TUNNEL & MINNING</h4>
            <p>In underground construction, such as tunnels and mines, macro fibers can be added to concrete to enhance its ability to withstand the challenges posed by the surrounding environment, including ground movement and dynamic loads.</p>
        </div>
        <div className="col-md-1"></div>

    </div>
    <div className="row">
        <div className="col-md-5 col-sm-12 col-xs-12 mt30">
            <img className="d-block w-100 img-fluid" src={app2}/>
        </div>
        <div className="col-md-6 col-sm-12 col-xs-12 mt30">
            <h4>Residential and Commercial Construction: PAVER BLOCK & FLOORING</h4>
            <p>Macro fibers find applications in various construction projects, ranging from residential buildings to commercial structures. They offer a cost-effective solution to improve the durability and longevity of concrete elements.</p>
        </div>
        <div className="col-md-1"></div>
    </div>
    <div className="row">
        <div className="col-md-5 col-sm-12 col-xs-12 mt30">
            <img className="d-block w-100 img-fluid" src={app3}/>
        </div>
        <div className="col-md-6 col-sm-12 col-xs-12 mt30">
            <h4>Precast Concrete Products</h4>
            <p>The use of macro fibers is common in the production of precast concrete products, such as panels, pipes, and blocks. This ensures that the precast elements maintain their structural integrity during handling, transportation, and installation.</p>
        </div>
        <div className="col-md-1"></div>
    </div>
    <div className="row">
        <div className="col-md-5 col-sm-12 col-xs-12 mt30">
            <img className="d-block w-100 img-fluid" src={app4}/>
        </div>
        <div className="col-md-6 col-sm-12 col-xs-12 mt30">
            <h4>Bridge Construction</h4>
            <p>Macro fibers contribute to the structural performance of bridges, where the concrete elements are exposed to a range of environmental conditions and loads. The fibers help mitigate the effects of temperature changes and dynamic forces.</p>
        </div>
        <div className="col-md-1"></div>
    </div>


</div>
{/* <!----------------========================================== end of about_page ======================================----------------> */}

    </div>
  )
}
