import './App.css';
import {Routes} from 'react-router-dom';
import {
	HashRouter as Router,
	//BrowserRouter as Router,
	Route,
} from "react-router-dom";

import './style.css';
import Header from './MyComponents/Header';
import Home from './MyComponents/Home';
import About from './MyComponents/About';
import Application from './MyComponents/Application';
import WhyMacro from './MyComponents/WhyMacro';
import Product from './MyComponents/Product';
import Quality from './MyComponents/Quality';
import Enquiry from './MyComponents/Enquiry';
import Contact from './MyComponents/Contact';
import Footer from './MyComponents/Footer';


function App() {
  return (
	<>

	<Router>
		<Header/>
		<Routes>
			<Route path="Header" element={ <Header/> } />
			<Route path="/" element={ <Home/> } />
			<Route path="About" element={ <About/> } />
			<Route path="Application" element={ <Application/> } />
			<Route path="WhyMacro" element={ <WhyMacro/> } />
			<Route path="Product" element={ <Product/> } />   
			<Route path="Quality" element={ <Quality/> } />
			<Route path="Enquiry" element={ <Enquiry/> } />
			<Route path="contact" element={ <Contact/> } />     
			<Route path="Footer" element={ <Footer/> } />
    	</Routes>
	  <Footer/>
  </Router>

	</>
  );
}

export default App;
