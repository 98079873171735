import React,{useEffect} from "react";
import Carousel from 'react-bootstrap/Carousel';

import AOS from 'aos';
import 'aos/dist/aos.css';

import qualitybanner from '../images/qualitybanner.jpg';
import quality from '../images/quality.jpg';

export default function About() {
    useEffect(() => {
        AOS.init();
      }, [])
  return (
    <div>

{/* <!----------------========================================== start of banner ======================================----------------> */}
<div className='container-fluid banner'>
        <Carousel autoPlay={true} interval={4000} controls={false} indicators={false} pause={false}>
            <Carousel.Item>
              <img
                className="d-block w-100"
                src={qualitybanner}
                alt="First slide"
              />
              <Carousel.Caption>
              <div class="carousel-caption row">
                    <div class="col-md-4 col-sm-12 col-xs-12" data-aos="fade-up">
                        <div class="row">
                            <h2>Quality</h2>
                        </div>
                    </div>
                </div>
              </Carousel.Caption>
          </Carousel.Item>
      </Carousel>   
</div>
{/* <!----------------========================================== end of banner ======================================----------------> */}

{/* <!----------------========================================== start of quality_page ======================================----------------> */}
<div class="container-fluid quality_page">
	<div class="row">
        <div class="col-md-6 col-sm-12 col-xs-12 mt30">
            <img src={quality} className="img-fluid" />
        </div>
        <div class="col-md-6 col-sm-12 col-xs-12 mt30">
            <h6>Quality Control</h6>
            <h2>We Provide Premium Quality Products</h2>
            <p>We believe that long-term business relationships can only be sustained by a commitment to provide the highest quality products and services. FWPL's specialised R&D team works hard to make sure that we deliver quality products. To meet industrial standards, we perform extensive quality testing and quality assurance on the products.</p>
            <ul>
            	<li>Modern Infrastructure</li>
                <li>Most Reliable </li>
                <li>Highest Performing Fibre Reinforcements</li>
            </ul>
            <p>We are well known for the diversity of the products that we manufacture and supply while maintaining the precise quality that is mentioned by our clients in their product requirements.</p>
        </div>
    </div>
</div>
{/* <!----------------========================================== end of quality_page ======================================----------------> */}

    </div>
  )
}
