import React,{useEffect} from "react";
import Carousel from 'react-bootstrap/Carousel';

import AOS from 'aos';
import 'aos/dist/aos.css';

import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

import "@fancyapps/ui/src/Fancybox/Fancybox.js";
import "@fancyapps/ui/dist/fancybox.css";

import g1 from '../images/g1.jpg';
import g2 from '../images/g2.jpg';

import productbanner from '../images/productbanner.jpg';

const options1 = {
    margin: 10,
    responsiveClass: true,
    nav: true,
    dots: false,
    autoplay: true,
    loop: true,
    navText: false,
    smartSpeed: 1000,
    responsive: {
        0: {items: 1,},
        400: {items: 1,},
        600: {items: 1,},
        700: {items: 1,},
        1000: {items: 1,}
    },
};

export default function Product() {
    useEffect(() => {
        AOS.init();
      }, [])
  return (
    <div>

{/* <!----------------========================================== start of banner ======================================----------------> */}
<div className='container-fluid banner'>
        <Carousel autoPlay={true} interval={4000} controls={false} indicators={false} pause={false}>
            <Carousel.Item>
              <img
                className="d-block w-100"
                src={productbanner}
                alt="First slide"
              />
              <Carousel.Caption>
              <div class="carousel-caption row">
                    <div class="col-md-4 col-sm-12 col-xs-12" data-aos="fade-up">
                        <div class="row">
                            <h2>Prodcuts</h2>
                        </div>
                    </div>
                </div>
              </Carousel.Caption>
          </Carousel.Item>
      </Carousel>   
</div>
{/* <!----------------========================================== end of banner ======================================----------------> */}

{/* <!----------------========================================== start of product_page ======================================----------------> */}
<div class="container-fluid product_page">
    <div class="row">
        <div class="col-md-5 col-sm-12 col-xs-12 mt30">
            <OwlCarousel className="slider-items owl-one owl-theme owl-carousel" {...options1}>
              <div class='item'>
                <a href="#" data-fancybox="gallery" data-src={g1} class="image"><img class="pic-1" src={g1} /></a>
              </div>
              <div class='item'>
                <a href="#" data-fancybox="gallery" data-src={g2} class="image"><img class="pic-1" src={g2} /></a>
              </div>
            </OwlCarousel>
        </div>
        <div class="col-md-7 col-sm-12 col-xs-12 mt30">
            <h6>FIBERCRETE WF</h6>
            <h2>Waved Macro Fiber for concrete reinforcement </h2>
            <h5><span>Fiber Length : 35mm & 48mm </span></h5>
            <p>FIBERCRETE WF is a Waved Macro Fiber for concrete reinforcement that complies with Standard
Specifications for Fiber Reinforced Concrete and Shotcrete, and is specifically designed to help
mitigate the formation of shrinkage cracking in concrete. Typically used at a dosage rate of 4 kg/m3.</p>
<p>FIBERCRETE WF has been shown to greatly reduce shrinkage cracking by as much as 85% when
compared to plain concrete. </p>
        </div>
    </div>
    <div className="row">
        <div className="col-md-5 col-sm-12 col-xs-12 mt15">
            <table class="table table-responsive">
                <tbody>
                    <tr>
                        <th class="col-1">Specification</th>
                        <th class="col-2">Details</th>
                    </tr>
                    <tr>
                        <td class="col-1">Colour</td>
                        <td class="col-2">Natural</td>
                    </tr>
                    <tr>
                        <td class="col-1">Specific Gravity</td>
                        <td class="col-2"> 0.91</td>
                    </tr>
                    <tr>
                        <td class="col-1">Melting Point </td>
                        <td class="col-2">165 °C</td>
                    </tr>
                    <tr>
                        <td class="col-1">Diameter/Thickness</td>
                        <td class="col-2">0.89 MM</td>
                    </tr>
                    <tr>
                        <td class="col-1">Tensile Strength </td>
                        <td class="col-2">600 MPa</td>
                    </tr>
                    <tr>
                        <td class="col-1">Young’s Modulus</td>
                        <td class="col-2"> 5.0 GPa</td>
                    </tr>
                    <tr>
                        <td class="col-1">Dosage</td>
                        <td class="col-2">4 Kg/m</td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div className="col-md-4 col-sm-12 col-xs-12 mt15">  
            <h5><span>Product Features</span></h5>
            <ul>
                <li>Waved form factor for</li>
                <li>adhesion</li>
                <li>Excellent Tensile Strength</li>
                <li>Uniform distribution</li>
                <li>Easy Anchoring</li>
                <li>Wire Mesh replacement</li>
                <li>Easy to use</li>
            </ul>
        </div>
        <div className="col-md-3 col-sm-12 col-xs-12 mt15">
            <h5><span>Applications</span></h5>
            <ul>
                <li>Shortcrete</li>
                <li>Tunnels</li>
                <li>Slope stabilization</li>
                <li>Rock support</li>
                <li>Pit slope angle</li>
                <li>Swimming pools</li>
                <li>Industrial Floorings</li>
                <li>Self Compacting Concrete </li>
            </ul>
        </div>
    </div>
</div>
{/* <!----------------========================================== end of product_page ======================================----------------> */}

    </div>
  )
}
