import React,{useEffect} from "react";
import {Link} from "react-router-dom";
import AOS from 'aos';
import 'aos/dist/aos.css';
import Carousel from 'react-bootstrap/Carousel';

import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

import CountUp from 'react-countup';

import slider1 from '../images/slider1.jpg';
import slider2 from '../images/slider2.jpg';
import about from '../images/about.png';
import sec5 from '../images/sec5.jpg';
import process1 from '../images/process-1-1.png';
import process2 from '../images/process-1-2.png';
import process3 from '../images/process-1-3.png';
import icon from '../images/Icon-white.png';
import pr1 from '../images/pr1.jpg';
import pr2 from '../images/pr2.jpg';
import pr3 from '../images/pr3.jpg';
import pr4 from '../images/pr4.jpg';
import pr5 from '../images/pr5.jpg';
import client1 from '../images/client-1.png';
import client2 from '../images/client-2.png';
import client3 from '../images/client-3.png';
import client4 from '../images/client-4.png';
import client5 from '../images/client-5.png';
import client6 from '../images/client-6.png';

const options1 = {
  margin: 15,
  responsiveClass: true,
  nav: true,
  dots: false,
  autoplay: true,
  loop: true,
  navText: false,
  smartSpeed: 1000,
  responsive: {
      0: {items: 1,},
      400: {items: 1,},
      600: {items: 2,},
      700: {items: 3,},
      1000: {items: 3,}
  },
};
const options2 = {
  margin: 15,
  responsiveClass: true,
  nav: true,
  dots: false,
  autoplay: true,
  navText: false,
  loop: true,
  smartSpeed: 1000,
  responsive: {
      0: {items: 1,},
      400: {items: 1,},
      600: {items: 1,},
      700: {items: 1,},
      1000: {items: 1,}
  },
};

export default function Home() {
    useEffect(() => {
        AOS.init();
      }, [])
  return (
    <div>

{/* <!----------------========================================== start of slider ======================================----------------> */}
<div className='container-fluid slider'>
        <Carousel autoPlay={true} interval={4000} controls={false} indicators={true} pause={false}>
            <Carousel.Item>
              <img
                className="d-block w-100"
                src={slider1}
                alt="First slide"
              />
              <Carousel.Caption>
              <div class="carousel-caption row">
                    <div class="col-md-5 col-sm-12 col-xs-12" data-aos="fade-up">
                        <div class="row back">
                            <h6 class="d-none d-sm-block d-md-block"><img src={icon}/> Fiberwell Pvt. Ltd.</h6>
                            <h2>Colourless Texture Fiber</h2>
                            <div class="d-none d-sm-block d-md-block"><Link to="/About"><a class="btn">Get Started  </a></Link></div>
                        </div>
                    </div>
                </div>
              </Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item>
              <img
                className="d-block w-100"
                src={slider2}
                alt="Second slide"
              />
              <Carousel.Caption>
                <div class="carousel-caption row">
                    <div class="col-md-5 col-sm-12 col-xs-12" data-aos="fade-up">
                        <div class="row back">
                            <h6 class="d-none d-sm-block d-md-block"><img src={icon}/> Fiberwell Pvt. Ltd.</h6>
                            <h2>Natural White Texture Fiber</h2>
                            <div class="d-none d-sm-block d-md-block"><Link to="/About"><a class="btn">Get Started  </a></Link></div>
                        </div>
                    </div>
                </div>
              </Carousel.Caption>
          </Carousel.Item>  
      </Carousel>   
</div>
{/* <!----------------========================================== end of slider ======================================----------------> */}

{/* <!----------------========================================== start of section1 ======================================----------------> */}
<div class="container-fluid section1">
    <div class="row mt30">
        <div class="col-md-12 col-sm-12 col-xs-12 text-center mt30">
            <h6>What We Offer</h6>
            <h2>Explore Wide Range of <br/>Fiber Products</h2>
        </div>
    </div>
    <div class="row">
        <div class="col-md-12 col-sm-12 col-xs-12 mt30">
            <OwlCarousel className="slider-items owl-one owl-theme owl-carousel" {...options1}>
                <div class='item'>
                    <div class="block">
                        <a class="img_bx">
                            <div class="image">
                                <img src={pr1} className="img-fluid" />
                                <div class="oh ho_1"></div><div class="oh ho_2"></div><div class="oh ho_3"></div><div class="oh ho_4"></div>
                            </div>
                        </a>
                        <div class="content_box">
                            <h4>Colourless Texture Fibers</h4>
                            <p>We are manufacturer & exporter of waved macrofiber for concrete reinforcement. </p>
                            <Link to="/Product"><a class="rd_more">Read More<i class="fa fa-long-arrow-right"></i></a></Link>
                        </div>
                    </div>
                </div>
                <div class="item">
                    <div class="block">
                        <a class="img_bx">
                            <div class="image">
                                <img src={pr2} className="img-fluid" />
                                <div class="oh ho_1"></div><div class="oh ho_2"></div><div class="oh ho_3"></div><div class="oh ho_4"></div>
                            </div>
                        </a>
                        <div class="content_box">
                            <h4>Fibers in Water Soluble Pucks</h4>
                            <p>We are manufacturer & exporter of waved macrofiber for concrete reinforcement.</p>
                            <Link to="/Product"><a class="rd_more">Read More<i class="fa fa-long-arrow-right"></i></a></Link>
                        </div>
                    </div>
                </div>
                <div class="item">
                    <div class="block">
                        <a class="img_bx">
                            <div class="image">
                                <img src={pr3} className="img-fluid" />
                                <div class="oh ho_1"></div><div class="oh ho_2"></div><div class="oh ho_3"></div><div class="oh ho_4"></div>
                            </div>
                        </a>
                        <div class="content_box">
                            <h4>Natural White Texture Fiber</h4>
                            <p>We are manufacturer & exporter of waved macrofiber for concrete reinforcement.</p>
                            <Link to="/Product"><a class="rd_more">Read More<i class="fa fa-long-arrow-right"></i></a></Link>
                        </div>
                    </div>
                </div>
                <div class="item">
                    <div class="block">
                        <a class="img_bx">
                            <div class="image">
                                <img src={pr4} className="img-fluid" />
                                <div class="oh ho_1"></div><div class="oh ho_2"></div><div class="oh ho_3"></div><div class="oh ho_4"></div>
                            </div>
                        </a>
                        <div class="content_box">
                            <h4>Waved Natural White Texture Fibers</h4>
                            <p>We are manufacturer & exporter of waved macrofiber for concrete reinforcement.</p>
                            <Link to="/Product"><a class="rd_more">Read More<i class="fa fa-long-arrow-right"></i></a></Link>
                        </div>
                    </div>
                </div>
                <div class="item">
                    <div class="block">
                        <a class="img_bx">
                            <div class="image">
                                <img src={pr5} className="img-fluid" />
                                <div class="oh ho_1"></div><div class="oh ho_2"></div><div class="oh ho_3"></div><div class="oh ho_4"></div>
                            </div>
                        </a>
                        <div class="content_box">
                            <h4>Wave Colourless Texture Fiber</h4>
                            <p>We are manufacturer & exporter of waved macrofiber for concrete reinforcement.</p>
                            <Link to="/Product"><a class="rd_more">Read More<i class="fa fa-long-arrow-right"></i></a></Link>
                        </div>
                    </div>
                </div>
            </OwlCarousel>
        </div>     
    </div>
</div>
{/* <!----------------========================================== end of section1 ======================================----------------> */}

{/* <!----------------========================================== start of section2 ======================================----------------> */}
<div class="container-fluid section2">
    <div class="row mt30">
        <div class="col-md-6 col-sm-12 col-xs-12 pr pl d-none d-sm-block d-md-block mt15" data-aos="fade-up">
            <div class="img"><img src={about} className="img-fluid" /></div>
        </div>
        <div class="col-md-6 col-sm-12 col-xs-12 mt15" data-aos="fade-up">
            <h6>About Company</h6>
            <h2>Manufacturer of Texture & Waved Fiber Supplier</h2>
            <p>FIBERWELL PVT. LTD. [FWPL] is a manufacturer and exporter of waved macrofiber for concrete reinforcement based in the vibrant state of Gujarat, India. We believe that long-term business relationships can only be sustained by a commitment to provide the highest quality products and services. </p>
            <p>At FWPL, we have a modern infrastructure and machinery facility that enables us to provide synthetic fibre concrete reinforcement as one of the most reliable and highest performing fibre reinforcements. It is made out of 100% virgin polypropylene fibre using international technology. which offers crack-free, leakage-free, and damp-free concrete and plaster.</p>
            <div class="btn"><Link to="/About"><a>Learn More <i class="fa fa-long-arrow-right"></i></a></Link></div>
        </div>
    </div>
</div>
{/* <!----------------========================================== end of section2 ======================================----------------> */}

{/* <!----------------========================================== start of section3 ======================================----------------> */}
<div class="container-fluid section3">
    <div class="row mt30">
        <div class="col-md-12 col-sm-12 col-xs-12 text-center mt30">
            <h6>Great Offer For Customer</h6>
            <h2>Amazing Service For Customers</h2>
        </div>
    </div>
    <div class="row">
        <div class="col-md-4 col-sm-12 col-xs-12 mt30">
            <div class="block">
                <div class="row">
                    <div class="col-md-3 col-sm-12 col-xs-12">
                        <div class="icon"><i class="flaticon flaticon-construction"></i></div>
                    </div>
                    <div class="col-md-9 col-sm-12 col-xs-12">
                        <h4>Enhanced Performance</h4>
                        <p>Unmatched strength and durability 
for your construction projects.</p>
                        {/* <Link to="/About"><a class="rd_more">Read More<i class="fa fa-long-arrow-right"></i></a></Link> */}
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-4 col-sm-12 col-xs-12 mt30">
            <div class="block">
                <div class="row">
                    <div class="col-md-3 col-sm-12 col-xs-12">
                        <div class="icon"><i class="flaticon flaticon-award"></i></div>
                    </div>
                    <div class="col-md-9 col-sm-12 col-xs-12">
                        <h4>Cost-Effective</h4>
                        <p>Reduce 
maintenance costs and extend the 
lifespan of structures.</p>
                        {/* <Link to="/About"><a class="rd_more">Read More<i class="fa fa-long-arrow-right"></i></a></Link> */}
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-4 col-sm-12 col-xs-12 mt30">
            <div class="block">
                <div class="row">
                    <div class="col-md-3 col-sm-12 col-xs-12">
                        <div class="icon"><i class="flaticon flaticon-maintenance"></i></div>
                    </div>
                    <div class="col-md-9 col-sm-12 col-xs-12">
                        <h4>Adaptable</h4>
                        <p>Tailored solutions for 
a variety of construction 
applications.
</p>
                        {/* <Link to="/About"><a class="rd_more">Read More<i class="fa fa-long-arrow-right"></i></a></Link> */}
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-4 col-sm-12 col-xs-12 mt30">
            <div class="block">
                <div class="row">
                    <div class="col-md-3 col-sm-12 col-xs-12">
                        <div class="icon"><i class="flaticon flaticon-settings-1"></i></div>
                    </div>
                    <div class="col-md-9 col-sm-12 col-xs-12">
                        <h4>Sustainability</h4>
                        <p>reducing environmental 
impact without compromising strength.</p>
                        {/* <Link to="/About"><a class="rd_more">Read More<i class="fa fa-long-arrow-right"></i></a></Link> */}
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-4 col-sm-12 col-xs-12 mt30">
            <div class="block">
                <div class="row">
                    <div class="col-md-3 col-sm-12 col-xs-12">
                        <div class="icon"><i class="flaticon flaticon-cogwheel"></i></div>
                    </div>
                    <div class="col-md-9 col-sm-12 col-xs-12">
                        <h4>Crack Control</h4>
                        <p>International technology which offers crack-free products.</p>
                        {/* <Link to="/About"><a class="rd_more">Read More<i class="fa fa-long-arrow-right"></i></a></Link> */}
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-4 col-sm-12 col-xs-12 mt30">
            <div class="block">
                <div class="row">
                    <div class="col-md-3 col-sm-12 col-xs-12">
                        <div class="icon"><i class="flaticon fa fa-chain-broken"></i></div>
                    </div>
                    <div class="col-md-9 col-sm-12 col-xs-12">
                        <h4>Durability</h4>
                        <p>we perform extensive quality testing on the products.</p>
                        {/* <Link to="/About"><a class="rd_more">Read More<i class="fa fa-long-arrow-right"></i></a></Link> */}
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
{/* <!----------------========================================== end of section3 ======================================----------------> */}

{/* <!----------------========================================== start of section4 ======================================----------------> */}
<div class="container-fluid section4">
    <div class="row">
        <div class="col-md-12 col-sm-12 col-xs-12 text-center mt30">
            <h6>Macro Fiber</h6>
            <h2>Explore the Future of
Construction: <br/> Macro 
Fibers Unveiled!</h2>
        </div>
    </div>
    <div class="row mt30">
        <div class="col-md-4 col-sm-12 col-xs-12 mt30">
            <div class="mt30">&nbsp;</div>
            <div class="block">
                <div class="process_box">
                    <div class="image_box">
                        <img src={process1} className="img-fluid" width="200" height="200"/>
                        <div class="num">Step 01</div>
                    </div>
                     <div class="content">
                        <div class="icon"><i class="flaticon flaticon-worldwide"></i></div>
                        {/* <h4>Explore Product range</h4>
                        <p>It is made out of 100% virgin polypropylene fibre using international technology.</p>
                        <div class="border_bg"></div> */}
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-4 col-sm-12 col-xs-12 mt30">
            <div class="block">
                <div class="process_box">
                    <div class="image_box">
                        <img src={process2} className="img-fluid" width="200" height="200"/>
                        <div class="num">Step 02</div>
                    </div>
                     <div class="content">
                        <div class="icon"><i class="flaticon flaticon-settings"></i></div>
                        {/* <h4>Choose Your Product</h4>
                        <p>We are well known for the diversity of the products that we manufacture and supply while maintaining the precise quality.</p>
                        <div class="border_bg"></div> */}
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-4 col-sm-12 col-xs-12 mt30">
            <div class="mt30">&nbsp;</div>
            <div class="block">
                <div class="process_box">
                    <div class="image_box">
                        <img src={process3} className="img-fluid" width="200" height="200"/>
                        <div class="num">Step 03</div>
                    </div>
                     <div class="content">
                        <div class="icon"><i class="flaticon flaticon-group"></i></div>
                        {/* <h4>Place order With Us</h4>
                        <p>We are committed to provide the highest quality products and services</p>
                        <div class="border_bg"></div> */}
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div className="row">
        <div className="col-md-2 col-sm-12 col-xs-12"></div>
        <div className="col-md-8 col-sm-12 col-xs-12 mt30">
            <h5>Uncoverthe game-changing 
potential of macro fibers in 
construction. From 
increased structural 
resilience to unprecedented 
durability, discover how our 
macro fiber solutions 
redefine industry standards</h5>
        </div>
        <div className="col-md-2 col-sm-12 col-xs-12"></div>
    </div>
</div>
{/* <!----------------========================================== end of section4 ======================================----------------> */}

{/* <!----------------========================================== start of section6 ======================================----------------> */}
<div class="container-fluid section6">
    <div class="sec6">
        <div class="row mt30">
            <div class="col-md-3 col-sm-12 col-xs-12 mt30">
                <div class="row">
                    <div class="col-md-3 col-sm-12 col-xs-12">
                        <i class="flaticon flaticon-placeholder"></i>
                    </div>
                    <div class="col-md-9 col-sm-12 col-xs-12" id="counter-box">
                        <h3><CountUp end={16} /> +</h3>
                        <p>Countries We Export</p>
                    </div>
                </div>
            </div>
            <div class="col-md-3 col-sm-12 col-xs-12 mt30">
                <div class="row">
                    <div class="col-md-3 col-sm-12 col-xs-12">
                        <i class="flaticon flaticon-group"></i>
                    </div>
                    <div class="col-md-9 col-sm-12 col-xs-12" id="counter-box">
                        <h3><CountUp end={3684} /> +</h3>
                        <p>Happy Customer</p>
                    </div>
                </div>
            </div>
            <div class="col-md-3 col-sm-12 col-xs-12 mt30">
                <div class="row">
                    <div class="col-md-3 col-sm-12 col-xs-12">
                        <i class="flaticon flaticon-worldwide"></i>
                    </div>
                    <div class="col-md-9 col-sm-12 col-xs-12" id="counter-box">
                        <h3><CountUp end={15} /> +</h3>
                        <p>Years of Experience</p>
                    </div>
                </div>
            </div>
            <div class="col-md-3 col-sm-12 col-xs-12 mt30">
                <div class="row">
                    <div class="col-md-3 col-sm-12 col-xs-12">
                        <i class="flaticon flaticon-award"></i>
                    </div>
                    <div class="col-md-9 col-sm-12 col-xs-12" id="counter-box">
                        <h3><CountUp end={43} /> +</h3>
                        <p>Product Supply</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
{/* <!----------------========================================== end of section6 ======================================----------------> */}

{/* <!----------------========================================== start of section5 ======================================----------------> */}
{/* <div class="container-fluid section5">
    <div class="row">
        <div class="col-md-5 col-sm-12 col-xs-12 pr pl mt30">
            <img src={sec5} className="img-fluid" />
        </div>
        <div class="col-md-1"></div>
        <div class="col-md-6 col-sm-12 col-xs-12 sec5 mt30">
            <div class="testimonial-bg">
                <OwlCarousel className="slider-items owl-one owl-theme owl-carousel" {...options2}>
                    <div class="item testimonial">
                        <p class="description">
                            <sup>"</sup> Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec at augue sed elit eleifend tempus. Etiam malesuada vulputate justo quis bibendum. Nam maximus ultricies rhoncus. Ut non felis vel enim dapibus. <sub>"</sub>
                        </p>
                        <h3 class="title">Williamson,</h3>
                        <span class="post">Web Developer</span>
                    </div>
                    <div class="item testimonial">
                        <p class="description">
                            <sup>"</sup> Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec at augue sed elit eleifend tempus. Etiam malesuada vulputate justo quis bibendum. Nam maximus ultricies rhoncus. Ut non felis vel enim dapibus. <sub>"</sub>
                        </p>
                        <h3 class="title">kristiana,</h3>
                        <span class="post">Web Designer</span>
                    </div>
                    <div class="item testimonial">
                        <p class="description">
                            <sup>"</sup> Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec at augue sed elit eleifend tempus. Etiam malesuada vulputate justo quis bibendum. Nam maximus ultricies rhoncus. Ut non felis vel enim dapibus. <sub>"</sub>
                        </p>
                        <h3 class="title">Williamson,</h3>
                        <span class="post">Web Developer</span>
                    </div>
                    <div class="item testimonial">
                        <p class="description">
                            <sup>"</sup> Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec at augue sed elit eleifend tempus. Etiam malesuada vulputate justo quis bibendum. Nam maximus ultricies rhoncus. Ut non felis vel enim dapibus. <sub>"</sub>
                        </p>
                        <h3 class="title">kristiana,</h3>
                        <span class="post">Web Designer</span>
                    </div>
                </OwlCarousel>
            </div>
        </div>
    </div>
</div> */}
{/* <!----------------========================================== end of section5 ======================================----------------> */}

{/* <!----------------========================================== start of section7 ======================================----------------> */}
{/* <div class="container-fluid section7">
    <div class="row mt30">
        <div class="col-md-5 col-sm-12 col-xs-12 mt30">
            <h6>Popular Clients</h6>
             <h2>We’ve 1520+ Global Premium Clients</h2>
             <p>We believe that long-term business relationships can only be sustained by a commitment to provide the highest quality products and services. FWPL's specialised R&D team works hard to make sure that we deliver quality products. To meet industrial standards, we perform extensive quality testing and quality assurance on the products.</p>
        </div>
        <div class="col-md-7 col-sm-12 col-xs-12 mt30">
            <div class="row">
                <div class="col-md-6 col-sm-12 col-xs-12">
                    <div class="block"><img src={client1} className="img-fluid" /></div>
                </div>
                <div class="col-md-6 col-sm-12 col-xs-12">
                    <div class="block"><img src={client2} className="img-fluid" /></div>
                </div>
                <div class="col-md-6 col-sm-12 col-xs-12">
                    <div class="block"><img src={client3} className="img-fluid" /></div>
                </div>
                <div class="col-md-6 col-sm-12 col-xs-12">
                    <div class="block"><img src={client4} className="img-fluid" /></div>
                </div>
                <div class="col-md-6 col-sm-12 col-xs-12">
                    <div class="block"><img src={client5} className="img-fluid" /></div>
                </div>
                <div class="col-md-6 col-sm-12 col-xs-12">
                    <div class="block"><img src={client6} className="img-fluid" /></div>
                </div>
            </div>
        </div>
    </div>
</div> */}
{/* <!----------------========================================== end of section7 ======================================----------------> */}

    </div>
  )
}
