import React,{useEffect} from "react";
import Carousel from 'react-bootstrap/Carousel';

import AOS from 'aos';
import 'aos/dist/aos.css';

import whymbanner from '../images/whymbanner.jpg';

export default function WhyMacro() {
    useEffect(() => {
        AOS.init();
      }, [])
  return (
    <div>

{/* <!----------------========================================== start of banner ======================================----------------> */}
<div className='container-fluid banner'>
        <Carousel autoPlay={true} interval={4000} controls={false} indicators={false} pause={false}>
            <Carousel.Item>
              <img
                className="d-block w-100"
                src={whymbanner}
                alt="First slide"
              />
              <Carousel.Caption>
              <div class="carousel-caption row">
                    <div class="col-md-4 col-sm-12 col-xs-12" data-aos="fade-up">
                        <div class="row">
                            <h2>Why Macro Fiber?</h2>
                        </div>
                    </div>
                </div>
              </Carousel.Caption>
          </Carousel.Item>
      </Carousel>   
</div>
{/* <!----------------========================================== end of banner ======================================----------------> */}

{/* <!----------------========================================== start of about_page ======================================----------------> */}
<div class="container-fluid about_page">
	<div class="row">
        <div class="col-md-12 col-sm-12 col-xs-12 mt30">
            <h6>Company Profile</h6>
            <h2>Why Macro Fiber?</h2>
        </div>
    </div>
    <div className="row">
        <div className="col-md-12 col-sm-12 col-xs-12 mt30">
            <h4>The Science Behind the Strength:</h4>
            <p>Dive into the technical brilliance of macro fibers. Understand the intricate details of how our innovative technology reinforces concrete, making it more resilient to various stresses.</p>
        </div>
        <div className="col-md-12 col-sm-12 col-xs-12 mt30">
            <h4>Mechanical Properties of FRC :</h4>
            <h5>Compressive Strength</h5>
            <p>The presence of fibers may alter the failure mode of cylinders, but the fiber effect will be minor on the improvement of compressive strength values (0 to 15 percent).</p>
            <h5>Modulus of Elasticity</h5>
            <p>Modulus of elasticity of FRC increases slightly with an increase in the fibers content. It was found that for each 1 percent increase in fiber content by volume, there is an increase of 3 percent in the modulus of elasticity.</p>
            <h5>Flexure</h5>
            <p>The flexural strength was reported to be increased by 2.5 times using 4 percent fiber</p>
            <h5>Splitting Tensile Strength</h5>
            <p>The presence of 3 percent fiber by volume was reported to increase the splitting tensile strength of mortar about 2.5 times that of the unreinforced one.</p>
            <h5>Toughness</h5>
            <p>For FRC, toughness is about 10 to 40 times that of plain concrete.</p>
            <h5>Fatigue Strength</h5>
            <p>The addition of fibers increases fatigue strength of about 90 percent.</p>
            <h5>Impact Resistance</h5>
            <p>The impact strength for fibrous concrete is generally 5 to 10 times that of plain concrete depending on the volume of fiber</p>
        </div>

        <div className="col-md-12 col-sm-12 col-xs-12 mt30">
            <table class="table table1 table-responsive">
                <tbody>
                    <tr>
                        <th class="col-1">Microfibers</th>
                        <th class="col-2">Macrofibers</th>
                    </tr>
                    <tr>
                        <td class="col-1">Microfibers are extremely fine fibers, usually with a diameter of fewer than 10 micrometers (μm).</td>
                        <td class="col-2">Macrofibers are larger fibers compared to microfibers, typically with a diameter exceeding
10 micrometers.</td>
                    </tr>
                    <tr>
                        <td class="col-1">They are typically made from synthetic materials such as polyester, nylon, or
polypropylene.</td>
                        <td class="col-2">They can be made from a variety of materials including natural fibers like cotton or wool, as
well as synthetic fibers like polyester or polypropylene.</td>
                    </tr>
                    <tr>
                        <td class="col-1">Microfibers are known for their softness, durability, and ability to wick moisture away from
the skin.</td>
                        <td class="col-2">Macrofibers are often used in applications where strength and durability are crucial. For
example, in construction, macrofibers can be added to concrete to enhance its tensile
strength and prevent cracking.</td>
                    </tr>
                    <tr>
                        <td class="col-1">They are commonly used in textiles for clothing, upholstery, and cleaning products like
microfiber towels.</td>
                        <td class="col-2">In textiles, macrofibers may be utilized for products such as rugs, carpets, or heavy-duty
fabrics where sturdiness is required.</td>
                    </tr>
                    <tr>
                        <td class="col-1">Microfiber technology is also employed in filtration systems, where the fine fibers can trap
tiny particles, making them effective in applications such as air and water filtration.</td>
                        <td class="col-2">Macrofibers are also used in industrial applications such as reinforcement in composites,
where their larger size provides reinforcement and structural support.
In summary, the main difference between microfibers and macrofibers lies in their size,
with microfibers being much finer and macrofibers being larger. Additionally, their
applications and properties vary, with microfibers often prized for softness and moisturewicking abilities, while macrofibers are valued for strength and durability.</td>
                    </tr>
                </tbody>
            </table>
        </div>


    </div>
</div>
{/* <!----------------========================================== end of about_page ======================================----------------> */}

    </div>
  )
}
