import React,{useEffect} from "react";
import Carousel from 'react-bootstrap/Carousel';

import AOS from 'aos';
import 'aos/dist/aos.css';

import contactbanner from '../images/contactbanner.jpg';

export default function Contact() {
    useEffect(() => {
        AOS.init();
      }, [])
  return (
    <div>

{/* <!----------------========================================== start of banner ======================================----------------> */}
<div className='container-fluid banner'>
        <Carousel autoPlay={true} interval={4000} controls={false} indicators={false} pause={false}>
            <Carousel.Item>
              <img
                className="d-block w-100"
                src={contactbanner}
                alt="First slide"
              />
              <Carousel.Caption>
              <div class="carousel-caption row">
                    <div class="col-md-4 col-sm-12 col-xs-12" data-aos="fade-up">
                        <div class="row">
                            <h2>Contact Us</h2>
                        </div>
                    </div>
                </div>
              </Carousel.Caption>
          </Carousel.Item>
      </Carousel>   
</div>
{/* <!----------------========================================== end of banner ======================================----------------> */}

{/* <!----------------========================================== start of contact_page ======================================----------------> */}
<div class="container-fluid contact_page">
	<div class="row">
        <div class="col-md-12 col-sm-12 col-xs-12 mt30">
            <h6>Contact Details</h6>
            <h2>Get In Touch With Us</h2>
        </div>
    </div>
    <div class="row mt30">
        <div class="col-md-4 col-sm-12 col-xs-12 mt30">
            <div class="serviceBox">
                <div class="service-icon">
                    <span><i class="fa flaticon flaticon-placeholder"></i></span>
                </div>
                <h3 class="title">Our Location</h3>
                <p class="description"><a href='https://maps.app.goo.gl/6oydMFJBYFFmx3766'>Plot No 254, G.I.D.C, Chitra, Bhavnagar-364004, Gujarat, INDIA.</a></p>
            </div>  
        </div>
        <div class="col-md-4 col-sm-12 col-xs-12 mt30">
            <div class="serviceBox">
                <div class="service-icon">
                    <span><i class="fa flaticon flaticon-phone-call"></i></span>
                </div>
                <h3 class="title">Call Us</h3>
                <p class="description"><a href='#'>Mitul Vaghasiya - Managing Director</a><br/>
                <a href='tel:8866307719'>+91 8866307719</a>, 
                <a href='tel:9925492129'> +91 9925492129</a>
                </p>
            </div>  
        </div>
        <div class="col-md-4 col-sm-12 col-xs-12 mt30">
            <div class="serviceBox">
                <div class="service-icon">
                    <span><i class="fa flaticon flaticon-email-2"></i></span>
                </div>
                <h3 class="title">Write Us</h3>
                <p class="description"><a href='mailto:info@fiberwellindia.com'>info@fiberwellindia.com</a><br/><a href='mailto:md@fiberwellindia.com'>md@fiberwellindia.com</a></p>
            </div>
        </div>
    </div>
</div>

{/* <div className="container-fluid map">
<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d14822.378017155608!2d72.08499723911693!3d21.75724061052467!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x395f50fea2066a1b%3A0xf128924cec03f0f1!2sChitra%20Gujarat%20Industrial%20Development%20Corporation%2C%20Bhavnagar%2C%20Gujarat%20364004!5e0!3m2!1sen!2sin!4v1681712366199!5m2!1sen!2sin" width="100%" height="350" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
</div> */}
{/* <!----------------========================================== end of contact_page ======================================----------------> */}

    </div>
  )
}
