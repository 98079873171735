import React from 'react';
import {Link} from "react-router-dom";

export default function Footer() {
  return (
    <div>
        {/* <!----------------========================================== start of footer ======================================----------------> */}
        <div class="container-fluid footer">
            <div class="row fsec1 mt30" style={{backgroundColor: "#008bf9", padding: "40px 40px 40px 40px"}} >
                <div class="col-md-6 col-sm-12 col-xs-12">
                    <h4>Feel Free To Contact Us & Start Your Journey With Us</h4>
                </div>
                <div class="col-md-3 col-sm-12 col-xs-12 mt30">
                    <Link to="/Contact"><div class="btn">Get In Touch With Us</div></Link>
                </div>
                <div class="col-md-3 col-sm-12 col-xs-12 mt30">
                    <div class="row">
                        <div class="col-md-3 col-sm-12 col-xs-12">
                            <div class="icon"><i class="flaticon flaticon-phone-call"></i></div>
                        </div>
                        <div class="col-md-9 col-sm-12 col-xs-12">
                            <h5 class="call">Need Help?</h5>
                            <h5 class="call_detail"><a href="tel:8866307719">+91 8866307719</a></h5>
                        </div>
                    </div>
                </div>
            </div>
            <div class="mt30">&nbsp;</div>
            <div class="row mt30">
                <div class="col-md-4 col-sm-12 col-xs-12 mt30" data-aos="fade-up">
                    <h3>About Us</h3>
                    <p class="mt15">FIBERWELL PVT. LTD. [FWPL] is a manufacturer and exporter of waved macrofiber for concrete reinforcement based in the vibrant state of Gujarat, India. </p>
                    <ul class="social">
                        <li><a href="https://www.facebook.com/profile.php?id=61557416408528&mibextid=ZbWKwL"><i class="fa fa-facebook wc"></i></a></li>
                        <li><a href="https://www.instagram.com/fiber_well_pvt_ltd?igsh=MWRzaGVjc2w5Y2Fp"><i class="fa fa-instagram wc"></i></a></li>
                        <li><a href="https://www.linkedin.com/authwall?trk=bf&trkInfo=AQHYy_6ihGqL4QAAAY5kxJaIsGxgrLsL-QgZUcuINWhkiUS1nDRaBkjuaUV3sZeNw2ZnqLmdr0wFv9a0IjvII8nfqrncofddJA62_9frahQiFt6CwR0HC1QnDqzbF_wnGm7wKWY=&original_referer=&sessionRedirect=https%3A%2F%2Fwww.linkedin.com%2Fin%2Ffiberwell-pvt-ltd-b3087b2bb%3Futm_source%3Dshare%26utm_campaign%3Dshare_via%26utm_content%3Dprofile%26utm_medium%3Dandroid_app"><i class="fa fa-linkedin" aria-hidden="true"></i></a></li>

                    </ul> 

                </div>
                <div class="col-md-5 col-sm-12 col-xs-12 mt30" data-aos="fade-up">
                    <h3>Useful Links</h3>
                    <ul class="link row">
                        <div class="col-md-4 col-sm-12 col-xs-12">
                            <li><Link to="/">Home</Link></li>
                            <li><Link to="/About">About Us</Link></li>
                            <li><Link to="/Quality">Quality</Link></li>
                            <li><Link to="/Enquiry">Enquiry Now</Link></li>
                            <li><Link to="/Contact">Contact Us</Link></li>
                        </div>
                        <div class="col-md-8 col-sm-12 col-xs-12" data-aos="fade-up">
                            <li><Link to="/Product">Colourless Texture Fiber</Link></li>
                            <li><Link to="/Product">Fibers in Water Soluble Pucks</Link></li>
                            <li><Link to="/Product">Natural White Texture Fiber</Link></li>
                            <li><Link to="/Product">Waved Natural White Texture Fibers</Link></li>
                            <li><Link to="/Product">Wave Colourless Texture Fiber</Link></li>
                            <li><Link to="/Product">PP Macro Synthetic Fibers</Link></li>
                        </div>
                    </ul>
                </div>
                <div class="col-md-3 col-sm-12 col-xs-12 mt30" data-aos="fade-up">
                    <h3>Contact Details</h3>
                    <p>Mon - Sat</p>
                    <h5>10:00 am to 06:00 pm</h5>
                    <p>The company is headed by a group of second-generation technocrats aiming to be the market leader in the industry.</p>
                </div>
            </div>
        </div>
        <div className="container-fluid footer-copy">
            <div class='row'>
                <div class="col-md-4 col-sm-12 col-xs-12">
                    <p>All Rights Reserved By Fiberwell Pvt. Ltd.</p>
                </div>
                <div class='col-md-8 col-sm-12 col-xs-12'>
                    <p className='pull-right'> Design by <a href="http://www.mwebtech.com/">M WEB-TECH</a></p>
                </div>
            </div>    
        </div>
        <a href="https://wa.me/" class="wp" target="_blank"><i class="fa fa-whatsapp my-float"></i></a>
{/* <!----------------========================================== end of footer ======================================----------------> */}
    </div>
  )
}
